
@media screen and (min-width: 1199px) {
    .container{
        justify-content: start !important;
    }
    .card{
        height: 100%;
    }

    .ant-card-body{
        width: 100% !important;
    }
}
@media screen and (max-width: 1199px) {
    .card-container{
        display: flex !important;
        align-items: center !important;
    }
}

@media screen and (max-width: 768px) {
    .bgImage{
        right: unset !important;
    }


    .card{
        padding: 20px !important;
    }
}